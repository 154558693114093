<template>
    <el-dialog
        v-loading="m__loading"
        :visible.sync="visible_in_line"
        :close-on-click-modal="true"
        :append-to-body="true"
        width="720px"
    >
        <div>
            <div>
                <p class="component-text-desc mb-6">
                    {{ $t('page.member.desc_assign_member_dialog') }}
                </p>

                <span
                    class="text-sm font-semibold"
                >{{ $t('common.email_address') }}:</span>
                <el-input
                    v-model="invite_member_form.email"
                    class="mt-2"
                    type="textarea"
                    :rows="4"
                    :placeholder="$t('page.member.email_placeholder')"
                >
                </el-input>
            </div>

            <div class="mt-10">
                <span
                    class="text-sm font-semibold"
                >{{ $t('common.access_settings') }}:</span>
                <div class="flex space-x-6 mt-2">
                    <div
                        class="flex-1 box-border border-[1px] border-[#dadfe3] rounded-[4px] p-4 cursor-pointer"
                        :class="{
                            'border-l-4 border-[#f60457]':
                                invite_member_form.user_role === 'ADMIN'
                        }"
                        @click="invite_member_form.user_role = 'ADMIN'"
                    >
                        <div
                            class="text-sm font-semibold"
                            :class="{
                                'text-[#f60457]':
                                    invite_member_form.user_role === 'ADMIN'
                            }"
                        >
                            {{ $t('common.admin') }}
                        </div>
                        <div class="mt-3 text-xs" style="word-break: break-word">
                            - {{ $t('page.member.manage_ad_accounts') }}
                            <br />
                            - {{ $t('page.member.manage_members') }}
                            <br />
                            - {{ $t('page.member.change_business_info') }}
                        </div>
                    </div>
                    <div
                        class="flex-1 box-border border-[1px] border-[#dadfe3] rounded-[4px] p-4 cursor-pointer"
                        :class="{
                            'border-l-4 border-[#f60457]':
                                invite_member_form.user_role === 'STANDARD'
                        }"
                        @click="invite_member_form.user_role = 'STANDARD'"
                    >
                        <div
                            class="text-sm font-semibold"
                            :class="{
                                'text-[#f60457]':
                                    invite_member_form.user_role === 'STANDARD'
                            }"
                        >
                            {{ $t('common.standard') }}
                        </div>
                        <div class="mt-3 text-xs" style="word-break: break-word">
                            -
                            {{ $t('page.member.access_assigned_ad_accounts') }}
                            <br />
                            - {{ $t('page.member.view_business_info') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div slot="title">
            <div class="text-left truncate w-4/5 text-base font-semibold">
                {{ $t('page.member.invite_new_member') }}
            </div>
            <div></div>
        </div>

        <template slot="footer">
            <div class="flex items-center justify-end mt-4">
                <el-button size="small" @click="visible_in_line = false">
                    {{ $t('button.close') }}
                </el-button>

                <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-check"
                    :loading="invite_loading"
                    @click="inviteMemberToBC"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import { inviteMemberToBC } from '@/services/atosa-tiktok-ads/business-center-member'
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import selectedBusinessCenterMixin from '@/plugins/mixins/selected-business-center'

export default {
    mixins: [selectedTiktokBusinessMixin, selectedBusinessCenterMixin],

    props: ['visible', 'business_center_id', 'tiktok_account_id'],

    data() {
        return {
            invite_member_form: {
                bc_id: '',
                email: '',
                emails: '',
                user_role: 'STANDARD',
                asset_ids: undefined,
                advertiser_role: undefined
            },
            invite_loading: false
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    methods: {
        async inviteMemberToBC() {
            if (!this.invite_member_form.email) {
                return
            }

            this.invite_loading = true
            this.invite_member_form.bc_id =
                this.m__selected_business_center.bc_id
            this.invite_member_form.emails = this.invite_member_form.email
                .split('\n')
                .map((i) => i.trim())
            try {
                await inviteMemberToBC(
                    this.m__selected_tiktok_business.id,
                    this.invite_member_form
                )
                this.p__showNotify(
                    'success',
                    this.$t('page.member.invite_member_successfully')
                )

                this.$emit('invited')

                this.visible_in_line = false
            } catch (e) {
                console.error(e)
            } finally {
                this.invite_loading = false
            }
        }
    }
}
</script>
