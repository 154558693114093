<template>
    <div>
        <section class="component-box">
            <div class="flex items-center">
                <label-outside-input-select
                    :label="$t('common.business_center')"
                >
                    <el-select
                        :value="m__selected_business_center"
                        value-key="bc_id"
                        size="small"
                        filterable
                        :placeholder="
                            $t('input.placeholder.select_business_center')
                        "
                        class="w-[320px]"
                        @change="m__handleChangeSelectedBCNotRefreshPage"
                    >
                        <el-option-group :label="$t('common.business_center')">
                            <el-option
                                v-for="item in m__business_centers"
                                :key="item.bc_id"
                                :label="item.name"
                                :value="item"
                            >
                                <div class="flex items-center py-1 space-x-2">
                                    <div class="flex flex-col">
                                        <span
                                            class="leading-5 text-xs font-semibold"
                                        >
                                            {{ item.name }}
                                        </span>
                                        <span class="component-text-desc">
                                            #{{ item.bc_id }}
                                        </span>
                                    </div>
                                </div>
                            </el-option>
                        </el-option-group>
                    </el-select>
                </label-outside-input-select>

                <div class="!ml-auto"></div>

                <!-- <el-button
                    :disabled="!current_package"
                    size="small"
                    icon="el-icon-plus"
                    type="primary"
                    @click="createBC"
                    >{{ $t('button.create') }}
                </el-button> -->
            </div>
        </section>

        <DetailBusinessCenter
            class="mt-4"
            :business_center="
                m__selected_business_center ? m__selected_business_center : null
            "
        ></DetailBusinessCenter>

        <AdvertiserAccount
            :bc_id="
                m__selected_business_center
                    ? m__selected_business_center.bc_id
                    : ''
            "
            class="mt-4"
        ></AdvertiserAccount>
    </div>
</template>

<script>
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import selectedBusinessCenterMixin from '@/plugins/mixins/selected-business-center'
import DetailBusinessCenter from './detail-business-center'
import AdvertiserAccount from './advertiser-account'

export default {
    components: {
        DetailBusinessCenter,
        AdvertiserAccount
    },

    mixins: [selectedTiktokBusinessMixin, selectedBusinessCenterMixin],

    data() {
        return {
            business_centers: []
        }
    },

    computed: {
        current_package() {
            return this.$store.getters.currentPackage.s__current_package
        }
    },

    methods: {}
}
</script>
