<template>
    <section v-loading="m__loading">
        <div class="flex items-center justify-between space-x-2 mb-2">
            <p class="font-semibold">
                {{ $t('common.members') }}
            </p>

            <div class="!ml-auto"></div>

            <el-button
                type="primary"
                size="small"
                icon="el-icon-thumb"
                @click="invite_member_dialog = true"
            >
                {{ $t('page.member.invite') }}
            </el-button>
        </div>

        <el-table :data="m__data_for_pagination">
            <el-table-column label="#" type="index" width="60" />

            <el-table-column label="Email">
                <template slot-scope="scope">
                    <div>{{ scope.row.user_email }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('common.name')">
                <template slot-scope="scope">
                    <div>{{ scope.row.user_name }}</div>
                    <div v-if="scope.row.user_id">#{{ scope.row.user_id }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('common.status')">
                <template slot-scope="scope">
                    <div v-if="scope.row.relation_status === 'PENDING'">
                        <el-tag type="warning" size="medium">
                            {{ $t('page.member.pending') }}
                        </el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('common.role')">
                <template slot-scope="scope">
                    <div>
                        {{ $t(`common.${scope.row.user_role.toLowerCase()}`) }}
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            class="mt-2"
            :current-page.sync="m__page_info.page"
            :page-size="m__page_info.page_size"
            layout="prev, pager, next, total"
            :total="m__page_info.total_number"
        />

        <AssignMemberDialog
            :visible.sync="invite_member_dialog"
            :tiktok_account_id="
                m__selected_tiktok_business ? m__selected_tiktok_business.id : ''
            "
            :business_center_id="bc_id"
            @invited="getMemberList"
        ></AssignMemberDialog>
    </section>
</template>

<script>
import paginationDataMixin from '@/plugins/mixins/pagination-data'
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import { getBusinessCenterMembers } from '@/services/atosa-tiktok-ads/business-center-member'
import AssignMemberDialog from './assign-member-dialog'

export default {
    components: {
        AssignMemberDialog
    },

    mixins: [selectedTiktokBusinessMixin, paginationDataMixin],

    props: ['bc_id'],

    data() {
        return {
            invite_member_dialog: false
        }
    },

    watch: {
        bc_id() {
            if (this.bc_id) {
                this.m__data_for_pagination = []
                this.m__page_info.page = 1
                this.getMemberList()
            }
        },

        m__selected_tiktok_business() {
            if (this.bc_id) {
                this.m__data_for_pagination = []
                this.m__page_info.page = 1
                this.getMemberList()
            }
        },

        'm__page_info.page': function () {
            this.getMemberList()
        }
    },

    mounted() {
        this.getMemberList()
    },

    methods: {
        async getMemberList() {
            if (!this.m__selected_tiktok_business || !this.bc_id) {
                return
            }

            this.m__loading = true

            try {
                const response = await getBusinessCenterMembers(
                    this.m__selected_tiktok_business.id,
                    this.bc_id,
                    this.m__page_info.page,
                    20
                )
                this.m__data_for_pagination = response.data.list
                this.m__page_info = response.data.page_info
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        }
    }
}
</script>
