var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('section',{staticClass:"component-box h-[calc(100vh-92px)] flex"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"desktop:min-w-[320px] desktop:w-[320px] tablet:min-w-[240px] tablet:w-[240px]"},[_c('div',{staticClass:"flex items-center mb-2"},[_c('p',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('common.advertiser_accounts'))+" ")]),_c('div',{staticClass:"!ml-auto"}),_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-plus"},on:{"click":_vm.createAdvertiserAccount}},[_vm._v(" "+_vm._s(_vm.$t('button.create'))+" ")])],1),_c('div',[_c('el-input',{attrs:{"size":"small","clearable":"","placeholder":_vm.$t('input.placeholder.search_by_name_or_id')},model:{value:(_vm.search_input),callback:function ($$v) {_vm.search_input=$$v},expression:"search_input"}},[_c('i',{staticClass:"el-input__icon el-icon-search",attrs:{"slot":"prefix"},slot:"prefix"})])],1),(_vm.filter_ads_account.length)?_c('ul',{staticClass:"mt-6 pr-3 -mr-5 overflow-scroll h-[calc(100%-76px)] flex-1"},_vm._l((_vm.filter_ads_account),function(item){return _c('li',{key:item.advertiser_id,staticClass:"rounded-xl bg-bg flex items-center mb-4 space-x-3 overflow-hidden cursor-pointer",class:[
                        _vm.selected_advertiser_id === item.advertiser_id
                            ? ''
                            : 'opacity-70 hover:opacity-100'
                    ],on:{"click":function($event){return _vm.handleChangeSelectedAdsAccount(item)}}},[_vm._m(0,true),_c('div',{staticClass:"flex-1 pr-2"},[_c('p',{staticClass:"text-sm font-semibold truncate w-[200px]"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"component-text-desc flex justify-between"},[_c('span',[_vm._v("#"+_vm._s(item.advertiser_id))]),(item.belong_to_atosa)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('page.campaign.belong_to_atosa'))+" ")]):_vm._e()])])])}),0):_c('div',{staticClass:"util-flex-center flex-col mt-12"},[_c('img',{staticClass:"w-[140px] h-[140px]",attrs:{"src":require('@/assets/images/no_data.png')}}),_c('p',{staticClass:"mt-4 text-desc-text text-sm"},[_vm._v(" "+_vm._s(_vm.$t('common.no_data'))+" ")])])]),_vm._m(1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.m__loading),expression:"m__loading"}],staticClass:"tablet:w-[calc(100%-280px)] desktop:w-[calc(100%-360px)]"},[_c('div',{staticClass:"bg-bg rounded-xl flex flex-wrap items-center gap-2 p-4 mb-4"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"text-xs text-brand-atosa--light font-semibold uppercase"},[_vm._v(" "+_vm._s(_vm.$t('common.ad_account_balance'))+" ")]),_c('div',{staticClass:"mt-1 text-2xl font-semibold"},[_vm._v(" "+_vm._s(_vm._f("f__format_currency_vnd")(_vm.selected_advertiser_balance))+" ")])]),(_vm.belong_to_atosa)?_c('el-button',{staticClass:"ml-4",attrs:{"type":"success","plain":"","size":"small","icon":"el-icon-money"},on:{"click":function($event){return _vm.$router.push({
                            path: `${_vm.p__group_route.ads_manager}/${
                                _vm.p__route.payment
                            }${
                                _vm.selected_advertiser_account &&
                                _vm.selected_advertiser_account.advertiser_id
                                    ? `?advertiser_id=${_vm.selected_advertiser_account.advertiser_id}`
                                    : ''
                            }`
                        })}}},[_vm._v(" "+_vm._s(_vm.$t('page.payment.add_balance'))+" ")]):_vm._e(),_c('div',{staticClass:"!ml-auto"}),_c('div',[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.handleRedirectAdsManager}},[_vm._v(" "+_vm._s(_vm.$t('page.member.ads_manager'))+" ")])],1),_c('div',[(_vm.selected_advertiser_id)?_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-thumb"},on:{"click":function($event){_vm.visible = true}}},[_vm._v(" "+_vm._s(_vm.$t('page.member.invite'))+" ")]):_vm._e()],1)],1),(_vm.members.length)?_c('ul',_vm._l((_vm.members),function(item){return _c('li',{key:item.user_id,staticClass:"flex border-border border-b-[1px] p-3 items-center hover:bg-bg"},[_c('div',[_c('p',{staticClass:"text-xs font-semibold"},[_vm._v(" "+_vm._s(item.user_name)+" ")]),_c('span',{staticClass:"component-text-desc"},[_vm._v(" Email : "+_vm._s(item.user_email)+" ")])]),_c('p',{staticClass:"ml-auto text-sm"},[_vm._v(" "+_vm._s(item.advertiser_role)+" ")])])}),0):_c('div',{staticClass:"util-flex-center flex-col mt-12"},[_c('img',{staticClass:"w-[140px] h-[140px]",attrs:{"src":require('@/assets/images/no_data.png')}}),_c('p',{staticClass:"mt-4 text-desc-text text-sm"},[_vm._v(" "+_vm._s(_vm.$t('common.no_data'))+" ")])])])]),_c('AssignMemberDialog',{attrs:{"visible":_vm.visible,"advertiser_id":_vm.selected_advertiser_id,"tiktok_account_id":_vm.m__selected_tiktok_business
                ? _vm.m__selected_tiktok_business.id
                : '',"business_center_id":_vm.bc_id},on:{"update:visible":function($event){_vm.visible=$event}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"util-flex-center min-w-[56px] w-14 h-14 bg-brand-atosa--light"},[_c('i',{staticClass:"el-icon-s-custom text-white text-xl"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-auto min-w-[40px] w-[40px] -my-5 util-flex-center"},[_c('div',{staticClass:"w-[1px] h-full bg-desc-text opacity-20"})])
}]

export { render, staticRenderFns }