import request from './_config'

const path = `bc_members`

export function getBusinessCenterMembers(account_s_id, bc_id, page, page_size) {
    return request({
        url: `${path}/${account_s_id}`,
        method: 'get',
        params: { bc_id, page, page_size }
    })
}

export function inviteMemberToBC(account_s_id, data) {
    return request({
        url: `${path}/invite/${account_s_id}`,
        method: 'post',
        data
    })
}
