<template>
    <el-dialog
        v-loading="m__loading"
        :visible.sync="visible_in_line"
        :append-to-body="true"
        :show-close="false"
        width="720px"
    >
        <p class="component-text-desc">
            {{ $t('page.tiktok_account.desc_assign_member_dialog') }}
        </p>

        <div
            class="flex border-border border-[1px] mt-4 rounded-lg overflow-hidden h-[400px]"
        >
            <div class="h-full w-[280px] border-border border-r-[1px]">
                <el-checkbox
                    v-model="check_all"
                    class="py-3 pl-3 hover:bg-bg border-b-border border-border border-b-[1px]"
                    :indeterminate="is_indeterminate"
                    @change="handleCheckAllChange"
                >
                    <p class="text-sm">
                        {{ $t('common.all') }}
                    </p>
                </el-checkbox>
                <div class="h-[calc(100%-44px)] overflow-auto">
                    <el-checkbox-group
                        v-model="checked_values"
                        @change="handleCheckedChange"
                    >
                        <el-checkbox
                            v-for="item in m__data_for_pagination"
                            :key="item.user_id"
                            class="py-3 pl-3 hover:bg-bg border-border border-b-[1px]"
                            :label="`${item.user_id}`"
                        >
                            <div>
                                <p class="text-sm">
                                    {{ item.user_name }}
                                </p>
                                <span class="component-text-desc">
                                    #{{ item.user_id }}
                                </span>
                            </div>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>

            <div class="p-4 h-full flex-1">
                <div class="p-4 bg-bg rounded-lg">
                    <div class="flex items-center">
                        <i class="el-icon-star-on text-xl"></i>

                        <p class="text-sm font-semibold ml-1">
                            {{ $t('common.admin') }}
                        </p>

                        <el-switch
                            v-model="switch_value_admin"
                            class="ml-auto"
                            size="small"
                        >
                        </el-switch>
                    </div>

                    <p class="text-xs text-desc-text mt-3">
                        {{ $t('page.tiktok_account.desc_admin') }}
                    </p>
                </div>

                <div class="p-4 bg-bg rounded-lg mt-2">
                    <div class="flex items-center">
                        <i class="el-icon-star-on text-xl"></i>

                        <p class="text-sm font-semibold ml-1">
                            {{ $t('common.operator') }}
                        </p>

                        <el-switch
                            v-model="switch_value_operator"
                            :disabled="switch_value_admin"
                            class="ml-auto"
                            size="small"
                        >
                        </el-switch>
                    </div>

                    <p class="text-xs text-desc-text mt-3">
                        {{ $t('page.tiktok_account.desc_operator') }}
                    </p>
                </div>

                <div class="p-4 bg-bg rounded-lg mt-2">
                    <div class="flex items-center">
                        <i class="el-icon-star-on text-xl"></i>

                        <p class="text-sm font-semibold ml-1">
                            {{ $t('common.analyst') }}
                        </p>

                        <el-switch
                            v-model="switch_value_analyst"
                            :disabled="switch_value_operator"
                            class="ml-auto"
                            size="small"
                        >
                        </el-switch>
                    </div>

                    <p class="text-xs text-desc-text mt-3">
                        {{ $t('page.tiktok_account.desc_analyst') }}
                    </p>
                </div>
            </div>
        </div>

        <template slot="title">
            <div class="text-left truncate w-4/5 text-base font-semibold">
                {{ $t('page.member.invite') }}
            </div>
            <div></div>
        </template>

        <template slot="footer">
            <div class="flex items-center justify-end">
                <el-button size="small" @click="visible_in_line = false">
                    {{ $t('button.close') }}
                </el-button>

                <el-button
                    type="primary"
                    icon="el-icon-check"
                    size="small"
                    :disabled="
                        !checked_values.length ||
                        (!switch_value_admin &&
                            !switch_value_operator &&
                            !switch_value_analyst)
                    "
                    @click="handleAssignMemberToAdsAccount"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import { getBusinessCenterMembers } from '@/services/atosa-tiktok-ads/business-center-member'
import { assignAsset } from '@/services/atosa-tiktok-ads/asset'
import paginationDataMixin from '@/plugins/mixins/pagination-data'

export default {
    mixins: [paginationDataMixin],

    props: [
        'visible',
        'business_center_id',
        'advertiser_id',
        'tiktok_account_id'
    ],

    data() {
        return {
            members: [],
            check_all: false,
            checked_values: [],
            is_indeterminate: false,
            switch_value_admin: false,
            switch_value_operator: false,
            switch_value_analyst: false
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        },

        list_value() {
            return this.m__data_for_pagination.map((item) => `${item.user_id}`)
        }
    },

    watch: {
        visible_in_line() {
            if (this.visible_in_line) {
                this.fetchMembersByAdsAccount()
            }
        },

        switch_value_admin() {
            this.switch_value_operator = this.switch_value_admin
        },

        switch_value_operator() {
            this.switch_value_analyst = this.switch_value_operator
        }
    },

    methods: {
        async fetchMembersByAdsAccount() {
            try {
                const response = await getBusinessCenterMembers(
                    this.tiktok_account_id,
                    this.business_center_id,
                    this.m__page_info.page,
                    20
                )

                this.m__data_for_pagination = response.data.list
            } catch (error) {
                console.error(error)
            }
        },

        handleCheckAllChange(value) {
            this.checked_values = value ? this.list_value : []
            this.is_indeterminate = false
        },

        handleCheckedChange(value) {
            const checkedCount = value.length
            this.check_all = checkedCount === this.list_value.length
            this.is_indeterminate =
                checkedCount > 0 && checkedCount < this.list_value.length
        },

        async handleAssignMemberToAdsAccount() {
            if (
                !this.checked_values.length ||
                (!this.switch_value_admin &&
                    !this.switch_value_operator &&
                    !this.switch_value_analyst)
            ) {
                return
            }

            this.m__loading = true

            const advertiser_role = this.switch_value_admin
                ? 'ADMIN'
                : this.switch_value_operator
                ? 'OPERATOR'
                : this.switch_value_analyst
                ? 'ANALYST'
                : 'ANALYST'

            try {
                await Promise.all(
                    this.checked_values.map(async (item) => {
                        try {
                            await assignAsset(this.tiktok_account_id, {
                                bc_id: this.business_center_id,
                                asset_type: 'ADVERTISER',
                                advertiser_role,
                                user_id: item,
                                asset_id: this.advertiser_id
                            })
                        } catch (error) {
                            console.error(error)
                            this.$t('message.xxx_fail', {
                                xxx: this.$t('page.member.invite')
                            })
                        }
                    })
                )

                this.$t('message.xxx_success', {
                    xxx: this.$t('page.member.invite')
                })

                this.p__event_bus.$emit('eb_reload_member_ads_account')

                this.visible_in_line = false
            } catch (error) {
                console.error(error)
            }
            this.m__loading = false
        }
    }
}
</script>
