var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.m__loading),expression:"m__loading"}],attrs:{"visible":_vm.visible_in_line,"close-on-click-modal":true,"append-to-body":true,"width":"720px"},on:{"update:visible":function($event){_vm.visible_in_line=$event}}},[_c('div',[_c('div',[_c('p',{staticClass:"component-text-desc mb-6"},[_vm._v(" "+_vm._s(_vm.$t('page.member.desc_assign_member_dialog'))+" ")]),_c('span',{staticClass:"text-sm font-semibold"},[_vm._v(_vm._s(_vm.$t('common.email_address'))+":")]),_c('el-input',{staticClass:"mt-2",attrs:{"type":"textarea","rows":4,"placeholder":_vm.$t('page.member.email_placeholder')},model:{value:(_vm.invite_member_form.email),callback:function ($$v) {_vm.$set(_vm.invite_member_form, "email", $$v)},expression:"invite_member_form.email"}})],1),_c('div',{staticClass:"mt-10"},[_c('span',{staticClass:"text-sm font-semibold"},[_vm._v(_vm._s(_vm.$t('common.access_settings'))+":")]),_c('div',{staticClass:"flex space-x-6 mt-2"},[_c('div',{staticClass:"flex-1 box-border border-[1px] border-[#dadfe3] rounded-[4px] p-4 cursor-pointer",class:{
                        'border-l-4 border-[#f60457]':
                            _vm.invite_member_form.user_role === 'ADMIN'
                    },on:{"click":function($event){_vm.invite_member_form.user_role = 'ADMIN'}}},[_c('div',{staticClass:"text-sm font-semibold",class:{
                            'text-[#f60457]':
                                _vm.invite_member_form.user_role === 'ADMIN'
                        }},[_vm._v(" "+_vm._s(_vm.$t('common.admin'))+" ")]),_c('div',{staticClass:"mt-3 text-xs",staticStyle:{"word-break":"break-word"}},[_vm._v(" - "+_vm._s(_vm.$t('page.member.manage_ad_accounts'))+" "),_c('br'),_vm._v(" - "+_vm._s(_vm.$t('page.member.manage_members'))+" "),_c('br'),_vm._v(" - "+_vm._s(_vm.$t('page.member.change_business_info'))+" ")])]),_c('div',{staticClass:"flex-1 box-border border-[1px] border-[#dadfe3] rounded-[4px] p-4 cursor-pointer",class:{
                        'border-l-4 border-[#f60457]':
                            _vm.invite_member_form.user_role === 'STANDARD'
                    },on:{"click":function($event){_vm.invite_member_form.user_role = 'STANDARD'}}},[_c('div',{staticClass:"text-sm font-semibold",class:{
                            'text-[#f60457]':
                                _vm.invite_member_form.user_role === 'STANDARD'
                        }},[_vm._v(" "+_vm._s(_vm.$t('common.standard'))+" ")]),_c('div',{staticClass:"mt-3 text-xs",staticStyle:{"word-break":"break-word"}},[_vm._v(" - "+_vm._s(_vm.$t('page.member.access_assigned_ad_accounts'))+" "),_c('br'),_vm._v(" - "+_vm._s(_vm.$t('page.member.view_business_info'))+" ")])])])])]),_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"text-left truncate w-4/5 text-base font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('page.member.invite_new_member'))+" ")]),_c('div')]),_c('template',{slot:"footer"},[_c('div',{staticClass:"flex items-center justify-end mt-4"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.visible_in_line = false}}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")]),_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-check","loading":_vm.invite_loading},on:{"click":_vm.inviteMemberToBC}},[_vm._v(" "+_vm._s(_vm.$t('button.confirm'))+" ")])],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }