<template>
    <section>
        <section class="component-box h-[calc(100vh-92px)] flex">
            <div
                v-loading="loading"
                class="desktop:min-w-[320px] desktop:w-[320px] tablet:min-w-[240px] tablet:w-[240px]"
            >
                <div class="flex items-center mb-2">
                    <p class="font-semibold">
                        {{ $t('common.advertiser_accounts') }}
                    </p>

                    <div class="!ml-auto"></div>

                    <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-plus"
                        @click="createAdvertiserAccount"
                    >
                        {{ $t('button.create') }}
                    </el-button>
                </div>

                <div>
                    <el-input
                        v-model="search_input"
                        size="small"
                        clearable
                        :placeholder="
                            $t('input.placeholder.search_by_name_or_id')
                        "
                    >
                        <i
                            slot="prefix"
                            class="el-input__icon el-icon-search"
                        ></i>
                    </el-input>
                </div>

                <ul
                    v-if="filter_ads_account.length"
                    class="mt-6 pr-3 -mr-5 overflow-scroll h-[calc(100%-76px)] flex-1"
                >
                    <li
                        v-for="item in filter_ads_account"
                        :key="item.advertiser_id"
                        class="rounded-xl bg-bg flex items-center mb-4 space-x-3 overflow-hidden cursor-pointer"
                        :class="[
                            selected_advertiser_id === item.advertiser_id
                                ? ''
                                : 'opacity-70 hover:opacity-100'
                        ]"
                        @click="handleChangeSelectedAdsAccount(item)"
                    >
                        <div
                            class="util-flex-center min-w-[56px] w-14 h-14 bg-brand-atosa--light"
                        >
                            <i class="el-icon-s-custom text-white text-xl"></i>
                        </div>
                        <div class="flex-1 pr-2">
                            <p class="text-sm font-semibold truncate w-[200px]">
                                {{ item.name }}
                            </p>

                            <div
                                class="component-text-desc flex justify-between"
                            >
                                <span>#{{ item.advertiser_id }}</span>
                                <span v-if="item.belong_to_atosa">
                                    {{ $t('page.campaign.belong_to_atosa') }}
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>

                <div v-else class="util-flex-center flex-col mt-12">
                    <img
                        class="w-[140px] h-[140px]"
                        :src="require('@/assets/images/no_data.png')"
                    />
                    <p class="mt-4 text-desc-text text-sm">
                        {{ $t('common.no_data') }}
                    </p>
                </div>
            </div>

            <div class="h-auto min-w-[40px] w-[40px] -my-5 util-flex-center">
                <div class="w-[1px] h-full bg-desc-text opacity-20"></div>
            </div>

            <div
                v-loading="m__loading"
                class="tablet:w-[calc(100%-280px)] desktop:w-[calc(100%-360px)]"
            >
                <div
                    class="bg-bg rounded-xl flex flex-wrap items-center gap-2 p-4 mb-4"
                >
                    <div class="flex flex-col">
                        <div
                            class="text-xs text-brand-atosa--light font-semibold uppercase"
                        >
                            {{ $t('common.ad_account_balance') }}
                        </div>
                        <div class="mt-1 text-2xl font-semibold">
                            {{
                                selected_advertiser_balance
                                    | f__format_currency_vnd
                            }}
                        </div>
                    </div>

                    <el-button
                        v-if="belong_to_atosa"
                        type="success"
                        plain
                        size="small"
                        icon="el-icon-money"
                        class="ml-4"
                        @click="
                            $router.push({
                                path: `${p__group_route.ads_manager}/${
                                    p__route.payment
                                }${
                                    selected_advertiser_account &&
                                    selected_advertiser_account.advertiser_id
                                        ? `?advertiser_id=${selected_advertiser_account.advertiser_id}`
                                        : ''
                                }`
                            })
                        "
                    >
                        {{ $t('page.payment.add_balance') }}
                    </el-button>

                    <div class="!ml-auto"></div>

                    <div>
                        <el-button
                            size="small"
                            @click="handleRedirectAdsManager"
                        >
                            {{ $t('page.member.ads_manager') }}
                        </el-button>
                    </div>

                    <div>
                        <el-button
                            v-if="selected_advertiser_id"
                            type="primary"
                            size="small"
                            icon="el-icon-thumb"
                            @click="visible = true"
                        >
                            {{ $t('page.member.invite') }}
                        </el-button>
                    </div>
                </div>

                <ul v-if="members.length">
                    <li
                        v-for="item in members"
                        :key="item.user_id"
                        class="flex border-border border-b-[1px] p-3 items-center hover:bg-bg"
                    >
                        <div>
                            <p class="text-xs font-semibold">
                                {{ item.user_name }}
                            </p>
                            <span class="component-text-desc">
                                Email : {{ item.user_email }}
                            </span>
                        </div>

                        <p class="ml-auto text-sm">
                            {{ item.advertiser_role }}
                        </p>
                    </li>
                </ul>

                <div v-else class="util-flex-center flex-col mt-12">
                    <img
                        class="w-[140px] h-[140px]"
                        :src="require('@/assets/images/no_data.png')"
                    />
                    <p class="mt-4 text-desc-text text-sm">
                        {{ $t('common.no_data') }}
                    </p>
                </div>
            </div>
        </section>

        <AssignMemberDialog
            :visible.sync="visible"
            :advertiser_id="selected_advertiser_id"
            :tiktok_account_id="
                m__selected_tiktok_business
                    ? m__selected_tiktok_business.id
                    : ''
            "
            :business_center_id="bc_id"
        ></AssignMemberDialog>
    </section>
</template>

<script>
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import selectedBusinessCenterMixin from '@/plugins/mixins/selected-business-center'
import { getMembersByAsset } from '@/services/atosa-tiktok-ads/asset'
import {
    getAdAccountByBC,
    getAdvertiserAccounts
} from '@/services/atosa-tiktok-ads/advertiser-account'
import AssignMemberDialog from './assign-member-dialog'
import _ from 'lodash'

export default {
    components: {
        AssignMemberDialog
    },

    mixins: [selectedTiktokBusinessMixin, selectedBusinessCenterMixin],

    props: ['bc_id'],

    data() {
        return {
            search_input: '',
            temp_search_input: '',
            loading: false,
            ads_account: [],
            members: [],
            selected_advertiser_id: '',
            selected_advertiser_account: null,
            visible: false
        }
    },

    computed: {
        filter_ads_account() {
            return (
                [...this.ads_account]
                    // .filter((item) => item.belong_to_atosa)
                    .filter(
                        (i) =>
                            i.name
                                .toLowerCase()
                                .indexOf(
                                    this.temp_search_input
                                        .toLowerCase()
                                        .replace(/^\//gi, '')
                                ) > -1 ||
                            i.advertiser_id
                                .toLowerCase()
                                .indexOf(
                                    this.temp_search_input
                                        .toLowerCase()
                                        .replace(/^\//gi, '')
                                ) > -1
                    )
            )
        },

        belong_to_atosa() {
            if (this.selected_advertiser_account) {
                return this.selected_advertiser_account.belong_to_atosa
            }

            return ''
        },

        // bc_id() {
        //     if (this.m__selected_business_center) {
        //         return this.m__selected_business_center.bc_id
        //     }

        //     return ''
        // },

        selected_advertiser_balance() {
            if (this.selected_advertiser_account) {
                return this.selected_advertiser_account.balance
            }

            return 0
        }
    },

    watch: {
        m__selected_tiktok_business() {
            this.fetchAdsAccount()
        },

        bc_id() {
            if (this.bc_id) {
                this.ads_account = []
                this.members = []
                this.selected_advertiser_id = ''
                this.selected_advertiser_account = null

                this.fetchAdsAccount()
            }
        },

        search_input() {
            this.debounceSearch()
        }
    },

    created() {
        this.debounceSearch = _.debounce(this.searchAdsAccount, 369)
    },

    beforeMount() {
        if (this.m__selected_business_center) {
            this.fetchAdsAccount()
        }

        this.p__event_bus.$on(
            'eb_reload_member_ads_account',
            this.fetchMembersByAdsAccount
        )
    },

    async mounted() {
        // await this.m__fetchAdsAccountsByTiktokBusiness()

        await this.fetchAdsAccount()
    },

    destroyed() {
        this.p__event_bus.$off(
            'eb_reload_member_ads_account',
            this.fetchMembersByAdsAccount
        )
    },

    methods: {
        async searchAdsAccount() {
            this.temp_search_input = this.search_input
        },

        debounceSearch() {
            console.warn('debounceSearch')
        },

        createAdvertiserAccount() {
            this.$router.push(
                `${this.p__group_route.ads_manager}/${this.p__route.tiktok_business}/connect?tiktok_business_id=${this.m__selected_tiktok_business.id}&bc_id=${this.bc_id}`
            )
        },

        handleChangeSelectedAdsAccount(ads_account) {
            this.selected_advertiser_account = ads_account

            this.selected_advertiser_id = ads_account.advertiser_id

            this.fetchMembersByAdsAccount()
        },

        async fetchMembersByAdsAccount() {
            if (!this.m__selected_tiktok_business || !this.bc_id) {
                return
            }

            this.m__loading = true

            try {
                const response = await getMembersByAsset(
                    this.m__selected_tiktok_business.id,
                    {
                        bc_id: this.bc_id,
                        asset_type: 'ADVERTISER',
                        asset_id: this.selected_advertiser_id
                    }
                )
                if (
                    this.p__isObjectNotEmpty(response.data) &&
                    response.data.list.length > 0
                ) {
                    this.members = response.data.list
                } else {
                    this.members = []
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async fetchAdsAccount() {
            if (!this.m__selected_tiktok_business || !this.bc_id) {
                return
            }

            this.loading = true

            try {
                const response = await getAdvertiserAccounts(
                    this.m__selected_tiktok_business.id,
                    this.bc_id
                )
                console.log(response.data)
                this.ads_account = response.data
            } catch (error) {
                console.error(error)
            }

            this.loading = false
        },

        handleRedirectAdsManager() {
            // localStorage.setItem(
            //     `id_ads_acc_${this.m__selected_tiktok_business.id}`,
            //     this.selected_advertiser_id
            // )
            this.$router.push({
                path: `${this.p__group_route.ads_manager}/${this.p__route.campaign}`
            })
        }
    }
}
</script>
