<template>
    <section class="component-box">
        <div v-if="business_center" class="flex items-center">
            <div class="p-3 bg-brand-atosa--light rounded-xl opacity-80">
                <i class="el-icon-suitcase text-white text-3xl"></i>
            </div>
            <div class="ml-3">
                <p class="font-semibold">
                    {{ business_center.name }}
                    <el-tag
                        :type="
                            business_center.status === 'ENABLE'
                                ? 'success'
                                : 'warning'
                        "
                        size="mini"
                    >
                        {{
                            business_center.status === 'ENABLE'
                                ? $t('common.enable')
                                : $t('common.disable')
                        }}
                    </el-tag>
                </p>
                <p class="text-desc-text text-xs mt-1">
                    #{{ business_center.bc_id }}
                </p>
            </div>

            <div class="flex ml-14 space-x-10 text-desc-text">
                <div>
                    <p class="text-xs">
                        {{ $t('common.currency') }}
                    </p>
                    <p class="text-sm">
                        {{ business_center.currency }}
                    </p>
                </div>
                <div>
                    <p class="text-xs">
                        {{ $t('common.timezone') }}
                    </p>
                    <p class="text-sm">
                        {{ business_center.timezone }}
                    </p>
                </div>
                <div>
                    <p class="text-xs">
                        {{ $t('common.company') }}
                    </p>
                    <p class="text-sm">
                        {{ business_center.company }}
                    </p>
                </div>
                <div>
                    <p class="text-xs">
                        {{ $t('common.registered_area') }}
                    </p>
                    <p class="text-sm">
                        {{ business_center.registered_area }}
                    </p>
                </div>
            </div>

            <!-- <el-date-picker
                v-model="m__ranger_date"
                class="!w-[360px] ml-auto"
                size="small"
                type="daterange"
                unlink-panels
                :start-placeholder="$t('date_picker.placeholder.start')"
                :end-placeholder="$t('date_picker.placeholder.end')"
                :picker-options="m__picker_options"
                :format="$t('common.format_value_date_picker')"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker> -->
        </div>

        <Member
            :bc_id="business_center ? business_center.bc_id : ''"
            class="mt-4"
        ></Member>
    </section>
</template>

<script>
import Member from './member'

export default {
    components: {
        Member
    },

    props: ['business_center']
}
</script>
